import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { attrs: { justify: "end", align: "center" } },
        [
          !_vm.gs.isMobile()
            ? _c(
                VCol,
                {
                  staticClass: "pa-0 text-center",
                  attrs: { cols: "12", md: "7" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      padding: "48px",
                      "padding-top": "0px",
                      "object-fit": "contain",
                      width: "inherit",
                      "max-height": "950px",
                    },
                    attrs: {
                      alt: "Banker Register",
                      src: require("@/assets/banker-register-bg.svg"),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _c(
            VCol,
            {
              class: "pa-0 " + (_vm.gs.isMobile() ? "" : "pr-12"),
              staticStyle: {
                display: "flex",
                "justify-content": "start",
                "align-items": "center",
                "align-self": "flex-start",
              },
              attrs: { cols: "12", md: "5" },
            },
            [
              _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      VRow,
                      {
                        class:
                          "text-" + (_vm.gs.isMobile() ? "center" : "left"),
                      },
                      [
                        _c(
                          VCol,
                          {
                            style: _vm.gs.isMobile()
                              ? "padding-top: 2rem;"
                              : "padding-top: 10rem;",
                            attrs: { cols: _vm.gs.isMobile() ? "12" : "8" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "px-5",
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "var(--white)",
                                  "font-size": "44px",
                                  "line-height": "42px",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("banker_register_finish_1")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          VCol,
                          { attrs: { cols: _vm.gs.isMobile() ? "12" : "8" } },
                          [
                            _c(
                              "h5",
                              {
                                staticClass: "px-5 my-3 mb-5",
                                staticStyle: {
                                  "font-size": "18px",
                                  "line-height": "21px",
                                  color: "var(--white)",
                                },
                                style: _vm.gs.isMobile()
                                  ? "width: 100%"
                                  : "width: 90%",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("banker_register_finish_2")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          VCol,
                          {
                            style: _vm.gs.isMobile()
                              ? "padding-top: 5rem"
                              : "padding-top: 15rem",
                            attrs: { cols: "12" },
                          },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass:
                                  "dxa-btn-primary-blue register-btn",
                                attrs: {
                                  "data-test":
                                    "Banker:FinishPage:AcceptPlatform",
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.access_plataform },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("access_plataform")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }